import { atom } from "recoil";

export const statusAtom = atom({
  key: "statusAtom",
  default: null,
});

export const stateAtom = atom({
  key: "stateAtom",
  default: null,
});

export const addedByAtom = atom({
  key: "addedByAtom",
  default: null,
});

export const notificationAtom = atom({
  key: "notificationAtom",
  default: null,
});

export const configAtom = atom({
  key: "configAtom",
  default: null,
});
